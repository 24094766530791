<template>
  <q-drawer
    v-model="userStore.visible"
    :width="335"
    side="right"
    behavior="mobile"
    no-swipe-open
    overlay
  >
    <div class="column fit">
      <q-scroll-area class="col">
        <q-list>
          <q-item
            v-if="userStore.data"
            to="/account"
          >
            <q-item-section avatar>
              <q-avatar
                size="50px"
                :icon="mdiHomeAccount"
              />
            </q-item-section>
            <q-item-section>
              <q-item-label>
                {{ join(userStore.data.firstName, userStore.data.lastName) }}
              </q-item-label>
              <q-item-label caption>
                {{ userStore.data.email }}
              </q-item-label>
              <q-item-label caption>
                Manager your basic account information.
              </q-item-label>
            </q-item-section>
          </q-item>
          <q-item to="/account/orders">
            <q-item-section avatar>
              <q-avatar
                size="50px"
                :icon="mdiReceiptTextClockOutline"
              />
            </q-item-section>
            <q-item-section>
              <q-item-label>Order History</q-item-label>
              <q-item-label caption>
                View your order history and make layaway payments
              </q-item-label>
            </q-item-section>
          </q-item>
          <q-item to="/account/addresses">
            <q-item-section avatar>
              <q-avatar
                size="50px"
                :icon="mdiMapLegend"
              />
            </q-item-section>
            <q-item-section>
              <q-item-label>Address Book</q-item-label>
              <q-item-label caption>
                Manage your addresses
              </q-item-label>
            </q-item-section>
          </q-item>
          <q-item to="/account/documents">
            <q-item-section avatar>
              <q-avatar
                size="50px"
                :icon="mdiFileDocumentMultipleOutline"
              />
            </q-item-section>
            <q-item-section>
              <q-item-label>Documents</q-item-label>
              <q-item-label caption>
                Manage your documents
              </q-item-label>
            </q-item-section>
          </q-item>
          <q-item to="/account/notifications">
            <q-item-section avatar>
              <q-avatar
                size="50px"
                :icon="mdiBellOutline"
              />
            </q-item-section>
            <q-item-section>
              <q-item-label>Notifications</q-item-label>
              <q-item-label caption>
                Manage the status of notifications
              </q-item-label>
            </q-item-section>
          </q-item>
        </q-list>
      </q-scroll-area>
      <div class="column q-gutter-y-sm q-pa-sm">
        <q-btn
          label="Log out"
          class="full-width"
          outline
          square
          @click="authStore.logout('/')"
        />
      </div>
    </div>
  </q-drawer>
</template>

<script lang="ts" setup>
import {
  mdiHomeAccount,
  mdiBellOutline,
  mdiFileDocumentMultipleOutline,
  mdiMapLegend,
  mdiReceiptTextClockOutline,
} from '@quasar/extras/mdi-v7';

const authStore = useAuthStore();
const userStore = useUserStore();
const { join } = useFormatting();
</script>
